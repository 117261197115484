import React from "react";

const DismissIcon = ({ ...props }) => {
  return (
    <div {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.28039 3.21973C3.98752 2.9268 3.51265 2.92675 3.21973 3.21961C2.9268 3.51248 2.92675 3.98735 3.21961 4.28027L11.1418 12.2041L3.22864 20.1173C2.93575 20.4102 2.93575 20.885 3.22864 21.1779C3.52153 21.4708 3.99641 21.4708 4.2893 21.1779L12.2024 13.2649L20.1238 21.188C20.4167 21.481 20.8916 21.481 21.1845 21.1881C21.4774 20.8953 21.4775 20.4204 21.1846 20.1275L13.263 12.2042L21.1794 4.2878C21.4723 3.99491 21.4723 3.52003 21.1794 3.22714C20.8865 2.93425 20.4117 2.93425 20.1188 3.22714L12.2025 11.1435L4.28039 3.21973Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default DismissIcon;
