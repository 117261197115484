import React from "react";
import PropTypes from "prop-types";

export const ButtonSecondary = ({ label, twClasses, ...props }) => {
  return (
    <button
      className={`font-inter rounded-full bg-grey5 text-darkNavy font-semibold py-2 px-8 hover:text-white hover:bg-grey2 ${twClasses}`}
      {...props}
    >
      {label}
    </button>
  );
};

ButtonSecondary.propTypes = {
  label: PropTypes.string.isRequired,
  twClasses: PropTypes.string,
  onClick: PropTypes.func,
};

ButtonSecondary.defaultProps = {
  onClick: undefined,
  twClasses: "",
};
