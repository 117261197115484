import React from "react";

const SearchIconButton = ({ twClasses, ...props }) => {
  return (
    <button
      className={`text-white font-inter rounded-full py-4 px-8 ${twClasses}`}
      {...props}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Iconly/Light/Search">
          <g id="Search">
            <circle
              id="Ellipse_739"
              cx="11.7659"
              cy="11.7664"
              r="8.98856"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Line_181"
              d="M18.0176 18.4849L21.5416 21.9997"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </svg>
    </button>
  );
};

export default SearchIconButton;
