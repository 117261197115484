import React, { useState, useRef, useEffect, createRef } from "react";
import PropTypes from "prop-types";
import Image from "next/image";
import getConfig from "next/config";

import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

import { Button } from "./Button";
import SearchIconButton from "./SearchIconButton";

import * as ga from "lib/ga";

const { publicRuntimeConfig } = getConfig();

const SearchBar = ({ placeholder, text, onClick }) => {
  const ref = useRef();
  const lineRefs = useRef([]);
  const [value, setValue] = useState("");
  const arrowKeyIndex = useRef(-1);
  const [showPredictions, setShowPredictions] = useState(false);
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: publicRuntimeConfig.gmapsApiKey,
    options: {
      fields: ["formatted_address", "address_components"],
      componentRestrictions: { country: "us" },
      types: ["address"],
    },
  });

  useEffect(() => {
    if (!showPredictions) {
      return;
    }

    const checkIfClickedOutside = (e) => {
      if (showPredictions && ref.current && !ref.current.contains(e.target)) {
        setShowPredictions(false);
      }
    };

    const changeSelectedSuggestion = (direction) => {
      const size = placePredictions.length - 1;
      let node = lineRefs.current[arrowKeyIndex.current];

      if (
        node &&
        node.current &&
        arrowKeyIndex.current >= 0 &&
        arrowKeyIndex.current <= size
      ) {
        lineRefs.current[arrowKeyIndex.current].current.classList.remove(
          "bg-grey5"
        );
      }
      if (
        direction === 40 &&
        arrowKeyIndex.current > -1 &&
        arrowKeyIndex.current < size
      ) {
        ++arrowKeyIndex.current;
      } else if (
        direction === 38 &&
        arrowKeyIndex.current > 0 &&
        arrowKeyIndex.current < size + 1
      ) {
        --arrowKeyIndex.current;
      } else {
        if (direction === 40) arrowKeyIndex.current = 0;
        else arrowKeyIndex.current = size;
      }

      node = lineRefs.current[arrowKeyIndex.current];
      if (node && node.current) {
        lineRefs.current[arrowKeyIndex.current].current.classList.add(
          "bg-grey5"
        );
        setValue(lineRefs.current[arrowKeyIndex.current].current.innerHTML);
      }
      return arrowKeyIndex.current;
    };

    const scrollWithArrowKeys = (e) => {
      switch (e.keyCode) {
        // UP
        case 38:
          changeSelectedSuggestion(38);
          break;
        // DOWN
        case 40:
          changeSelectedSuggestion(40);
          break;
        // Enter
        case 13:
          setShowPredictions(false);
          handleSelection(placePredictions[arrowKeyIndex.current]);
          break;
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    document.addEventListener("keydown", scrollWithArrowKeys);
    return () => {
      // Cleanup the event listeners
      document.removeEventListener("mousedown", checkIfClickedOutside);
      document.removeEventListener("keydown", scrollWithArrowKeys);
    };
  });

  const handleSelection = (prediction) => {
    var description = prediction.description;
    console.log("description: " + prediction.description);

    setValue(prediction.description);
    setShowPredictions(false);

    var place_id = prediction.place_id;
    console.log("place_id:" + prediction.place_id);

    ga.event({
      action: "addressSelected",
      params: {
        address: description,
      },
    });

    window.location.href =
      publicRuntimeConfig.appUrl + "/get-started?place_id=" + place_id;
  };

  const renderPredictions = () => {
    lineRefs.current = placePredictions.map(
      (_, i) => lineRefs.current[i] ?? createRef()
    );

    return placePredictions.map((prediction, idx) => (
      <li
        className="text-left cursor-pointer px-6 py-3 hover:bg-zinc-200"
        key={idx}
        ref={lineRefs.current[idx]}
        onClick={() => handleSelection(prediction)}
      >
        {prediction.description}
      </li>
    ));
  };

  const renderGoogleLogo = () => {
    return (
      placePredictions.length > 0 && (
        <li className="flex justify-center p-2">
          <Image
            loader={() =>
              "https://res.cloudinary.com/htlasjvip/image/upload/v1627937611/powered_by_google_on_white_hdpi_vgouhs.png"
            }
            src="powered_by_google_on_white_opsprp.png"
            alt="powered by google logo"
            width={96}
            height={12}
          />
        </li>
      )
    );
  };

  return (
    <div className="inset-x-0 px-4 md:px-0 md:p-0 md:inset-auto w-screen md:searchbar-md z-10 absolute">
      <div
        className={`bg-white flex justify-between items-center ${
          showPredictions && placePredictions.length > 0
            ? "rounded-t-3xl"
            : "rounded-full"
        }`}
      >
        <input
          className="flex-grow rounded-l-full py-5 pl-6 -mr-6 cta-search-mobile md:cta-search focus:outline-none"
          type="text"
          value={value}
          placeholder={placeholder}
          autoComplete="off"
          onChange={(evt) => {
            setValue(evt.target.value);
            getPlacePredictions({ input: evt.target.value });
            setShowPredictions(true);
          }}
        />
        <div className="flex p-1">
          <Button
            label={text}
            onClick={onClick}
            twClasses={"hidden md:flex bg-secondary"}
          />
          <SearchIconButton
            onClick={onClick}
            twClasses={"md:hidden flex bg-secondary"}
          />
        </div>
      </div>
      {showPredictions && (
        <ul
          className="bg-white z-50 text-black text-base rounded-b-3xl"
          ref={ref}
        >
          {renderPredictions()}
          {renderGoogleLogo()}
        </ul>
      )}
    </div>
  );
};

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SearchBar;
