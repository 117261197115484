import React from "react";
import PropTypes from "prop-types";
import Link from "next/link";

import { ButtonSecondary } from "marketing/ButtonSecondary";
import DismissIcon from "marketing/DismissIcon";
import MenuIcon from "marketing/MenuIcon";

const NavigationBar = ({ homeLink, menuItems, cta, userEmail, onClick }) => {
  function hideDropdown() {
    document.getElementById("mobile-dropdown").classList.add("hidden");
  }

  function showDropdown() {
    document.getElementById("mobile-dropdown").classList.remove("hidden");
  }

  return (
    <nav className="flex flex-row sticky top-0 shadow-sm justify-between text-darkNavy bg-frameColor font-inter align-center w-full z-50 h-16">
      {/* Mobile Menu Icon */}
      <div className="flex md:hidden w-16 h-16">
        <button
          className="p-5 w-full h-full text-center text-darkNavy cursor-pointer text-xl leading-none border-transparent rounded bg-transparent outline-none focus:outline-none"
          type="button"
          onClick={() => {
            showDropdown();
          }}
        >
          <MenuIcon id="menu-icon" />
        </button>
      </div>

      {/* Firemaps */}
      <div className="pl-0 pr-16 md:pl-16 md:pr-0 my-auto relative flex justify-center md:w-auto md:static md:block md:justify-start">
        <div className="font-bold text-lg text-center md:flex">
          <Link href={homeLink}>Firemaps</Link>
        </div>
      </div>

      {/* CTA link */}
      <div className="flex flex-grow justify-end md:hidden pr-5 my-auto relative">
        <div className="font-bold text-sm text-right">
          <Link href={cta.href}>
            <a className="text-purple font-semibold">{cta.altTitle} &rsaquo;</a>
          </Link>
        </div>
      </div>

      {/* Mobile Dropdown */}
      <div
        id="mobile-dropdown"
        className="flex flex-col hidden mt-16 w-screen h-screen absolute bg-purple"
      >
        <div className="flex flex-row">
          <div className="flex w-16 h-16 m-2">
            <button
              className="p-5 w-full h-full text-center text-darkNavy cursor-pointer text-xl leading-none border-transparent rounded bg-transparent outline-none focus:outline-none"
              type="button"
              onClick={() => {
                hideDropdown();
              }}
            >
              <DismissIcon />
            </button>
          </div>
          <div className="flex flex-grow"></div>
        </div>

        <div className="flex flex-col items-center my-auto pb-56">
          {menuItems.map((item, idx) => (
            <div className="flex h2-white pb-10" key={idx}>
              <a href={item.href}>{item.title}</a>
            </div>
          ))}
          {cta && (
            <div>
              <ButtonSecondary
                label={cta.title}
                twClasses={"py-4"}
                onClick={
                  onClick ? onClick : () => (window.location.href = cta.href)
                }
              />
            </div>
          )}
        </div>
      </div>

      {/* Desktop Content */}
      <div className="pr-16 hidden md:flex font-semibold text-sm items-center">
        <ul className="flex flex-row list-none md:ml-auto ">
          {menuItems.map((item, idx) => (
            <li className={`flex p-4 mr-6`} key={idx}>
              <Link href={item.href}>
                <a className="md:flex hover:text-purple font-semibold">
                  {item.title}
                </a>
              </Link>
            </li>
          ))}
        </ul>
        {userEmail && (
          <div className="flex flex-grow justify-end pr-10 my-auto relative">
            <div className="text-sm text-right font-normal">
              <p>Signed in as {userEmail}</p>
            </div>
          </div>
        )}
        {cta && (
          <div>
            <ButtonSecondary
              label={cta.title}
              onClick={
                onClick ? onClick : () => (window.location.href = cta.href)
              }
            />
          </div>
        )}
      </div>
    </nav>
  );
};

NavigationBar.propTypes = {
  homeLink: PropTypes.string,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
    }).isRequired
  ),
  cta: PropTypes.shape({
    href: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  userEmail: PropTypes.string,
  onClick: PropTypes.func,
};

NavigationBar.defaultProps = {
  homeLink: "/",
  menuItems: [],
  cta: undefined,
  userEmail: "",
  onClick: undefined,
};

export default NavigationBar;
