import React from "react";
import PropTypes from "prop-types";
import getConfig from "next/config";
import Link from "next/link";

import SearchBar from "./SearchBar";

const { publicRuntimeConfig } = getConfig();

const Footer = () => {
  return (
    <div>
      <div className="flex flex-col xl:flex-row bg-darkNavy ">
        <div className="flex flex-col xl:flex-row mx-4 md:mx-auto md:w-160 xl:w-305">
          <div className="h3-title flex mt-10 mb-8 lg:mt-12 xl:my-12 xl:max-w-145">
            Schedule your free 3D inspection and let Firemaps handle your
            wildfire defense.
          </div>
          <div className="flex pb-20 md:pb-28 xl:pb-0 xl:mt-12">
            <SearchBar
              text="Get Started"
              placeholder="Enter your home address"
              onClick={() => console.log("clicked")}
            />
          </div>
        </div>
      </div>

      <div className="bg-secondary pl-4 pr-3.5 lg:px-16 pt-24 pb-8">
        <div className="flex flex-row max-w-md">
          <div className="flex flex-grow h4-title mt-auto">Firemaps</div>
          <div className="flex flex-col sitemap w-28">
            <div>
              <Link href={publicRuntimeConfig.appUrl + "/get-started"}>
                <a>Sign Up</a>
              </Link>
            </div>
            <div>
              <Link href="/">
                <a>Overview</a>
              </Link>
            </div>
            <div>
              <Link href="/services">
                <a>Services</a>
              </Link>
            </div>
          </div>
          <div className="flex flex-col sitemap w-20 justify-end">
            <a
              href="https://www.facebook.com/Firemaps-105411074956602"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
            <a href="mailto:contact@firemaps.co">Contact</a>
          </div>
        </div>

        <hr className="opacity-50 mt-4" />

        <div className="flex flex-row mt-8 opacity-50 max-w-md">
          <div className="flex flex-grow sitemap-footer-bold">
            ©2021 Firemaps Inc.
          </div>
          <div className="flex flex-col sitemap-footer w-28">
            <a href="/terms.pdf">Terms of Service</a>
          </div>
          <div className="flex flex-col sitemap-footer w-20">
            <a href="/privacy.pdf">Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
