import React from "react";
import PropTypes from "prop-types";

export const Button = ({ label, twClasses, ...props }) => {
  return (
    <button
      className={`cta-main rounded-full py-4 px-8 ${twClasses}`}
      {...props}
    >
      {label}
    </button>
  );
};

Button.propTypes = {
  label: PropTypes.string.isRequired,
  twClasses: PropTypes.string,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  onClick: undefined,
  twClasses: "",
};
